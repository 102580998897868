<template>
    <div id="shaoxing">
        <div class="home-left">
            <shaoxingleft></shaoxingleft>
        </div>
        <div class="home-top">
            <shaoxingtop></shaoxingtop>
        </div>
        <div class="home-right">
            <shaoxingright></shaoxingright>
        </div>
    </div>
</template>

<script>
export default {
    components:{
        shaoxingleft: () => import('../charts/shaoxingone/shaoxingleft.vue'),
        shaoxingtop: () => import('../charts/shaoxingone/shaoxingtop.vue'),
        shaoxingright: () => import('../charts/shaoxingone/shaoxingright.vue'),
    }
}
</script>

<style scoped>

</style>